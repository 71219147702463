import React from 'react';
import { Input, FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import Dropdown from './Dropdown';

// import MultiSelectDropdown from './MultiSelectDropdown';
import List from './List';
// import MultiSelectList from './MultiSelectList';
import SearchableField from './SearchableField';
import RadioGroup from './RadioGroup';
import Checkbox from './Checkbox';
import TextGet from '../components/shared/TextGet';

const FieldsRendering = ({ control, errors, fieldSchema, records, showLabels = true }) => {

  return (
    <>
      {fieldSchema.map((field) => (
//        <FormControl key={field.name} isInvalid={errors[field.name]} isRequired={field.required} style={{ width: field.width }}>
        <FormControl 
          key={field.name} 
          isInvalid={errors[field.name]} 
          isRequired={field.required} 
          style={field.width ? { width: field.width } : {}}
        >
 {/*        <FormLabel htmlFor={field.name}>{field.label}</FormLabel>   */}
          {showLabels && <FormLabel htmlFor={field.name}>{field.label}</FormLabel>}          

          <Controller
            name={field.name}
            control={control}
            rules={{ required: field.required }}
            render={({ field: { ref, onChange, value, ...rest } }) => {
              switch (field.type) {
                case 'dropdown':
                  return <Dropdown {...rest} lov={field.lov} placeholder={field.label} value={value} onChange={onChange} />;
                // case 'multiSelectDropdown':
                //   return <MultiSelectDropdown {...rest} lov={field.lov} placeholder={field.label} value={value} onChange={onChange} />;
                case 'list':
                  return <List lov={field.lov} field={field} {...rest} value={value} onChange={onChange} />;
                // case 'multiSelectList':
                //     return <MultiSelectList {...rest} lov={field.lov} placeholder={field.label} value={value} onChange={onChange} />;                  
                case 'search':
                      return <SearchableField {...rest} apiUrl={field.apiUrl} placeholder={field.label} value={value} onChange={onChange} />;
                case 'radio':
                  return <RadioGroup field={field} value={value} onChange={onChange} error={errors[field.name]} {...rest} />;
                case 'checkbox':
                  return <Checkbox field={field} value={value} onChange={onChange} error={errors[field.name]} {...rest} />;
                case 'date':
                  return <Input {...rest} id={field.name} type="date" value={value} onChange={onChange} required={field.required} />;
                case 'cms':
                    return <TextGet {...rest} id={field.name} currentTextFile={value} onChange={onChange} required={field.required} />;
                default:
                  return <Input {...rest} id={field.name} type={field.type} value={value} onChange={onChange} readOnly={field.readOnly} required={field.required} fontWeight="bold" fontSize="lg" color="red"/>;
              }
            }}
          />
          <FormErrorMessage>
            {errors[field.name] && `${field.label} is required`}
          </FormErrorMessage>
        </FormControl>
      ))}
    </>
  );
};

export default FieldsRendering;
