import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Input } from "@chakra-ui/react";
import apiUrlConfig_json from '../../config/apiUrl.json';

const ProcessEvaluations = () => {
    const apiUrl = apiUrlConfig_json.SHR;

    const [frequency, setFrequency] = useState(6);
    const [batchSize, setBatchSize] = useState(1000);
    const [perfStdFile1, setPerfStdFile1] = useState('');

    const startProcessing = async () => {
        try {
            const response = await axios.post(`${apiUrl}run_process_evaluation/`, {
                action: 'start',
                frequency: frequency,
                batch_size: batchSize,
                perf_std_file_1: perfStdFile1
            });
            console.log('Processing started:', response.data);
        } catch (error) {
            console.error('Error starting processing:', error);
        }
    };

    const runOnce = async () => {
        try {
            const response = await axios.post(`${apiUrl}run_process_evaluation/`, {
                action: 'start',
                batch_size: batchSize,
                perf_std_file_1: perfStdFile1
            });
            console.log('Processing started:', response.data);
        } catch (error) {
            console.error('Error starting processing:', error);
        }
    };

    const stopProcessing = async () => {
        try {
            const response = await axios.post(`${apiUrl}run_process_evaluation/`, { action: 'stop' });
            console.log('Processing stopped:', response.data);
        } catch (error) {
            console.error('Error stopping processing:', error);
        }
    };

    return (
        <div>
            <Box mt={2}>
                <Button colorScheme="green" onClick={startProcessing}>Start Processing Every {frequency} Minutes</Button>
            </Box>
            <Box mt={2}>
                <Button colorScheme="blue" onClick={runOnce}>Run Once</Button>
            </Box>
            <Box mt={2}>
                <Button colorScheme="red" onClick={stopProcessing}>Stop Processing</Button>
            </Box>
            <Box mt={2} display="flex" alignItems="left">
                <label htmlFor="frequency" style={{ marginRight: '10px' }}>Frequency</label>
                <Input
                    id="frequency"
                    placeholder="Frequency"
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    style={{ width: '50vw' }}
                />
            </Box>
            <Box mt={2} display="flex" alignItems="left">
                <label htmlFor="batchSize" style={{ marginRight: '10px' }}>Batch Size</label>
                <Input
                    id="batchSize"
                    placeholder="Batch Size"
                    value={batchSize}
                    onChange={(e) => setBatchSize(e.target.value)}
                    style={{ width: '50vw' }}
                />
            </Box>
            <Box mt={2} display="flex" alignItems="left">
                <label htmlFor="perfStdFile" style={{ marginRight: '10px' }}>Batch Size</label>
                <Input
                    id="perfStdFile"
                    placeholder="Performance Std File 1"
                    value={perfStdFile1}
                    onChange={(e) => setPerfStdFile1(e.target.value)}
                    style={{ width: '50vw' }}
                />
            </Box>
        </div>
    );
};

export default ProcessEvaluations;
